<template>
  <div>
    <notifications group="notification" position="top right" />

    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Body-->
          <div class="card-body d-flex flex-column">
            <div class="mb-5">
              <div class="row">
                <div class="col-md-3">
                  <button
                    @click="yesterday"
                    class="btn btn-light-primary btn-block mb-2"
                  >
                    Yesterday
                  </button>
                  <button
                    @click="last7Days"
                    class="btn btn-light-primary btn-block mb-2"
                  >
                    Last 7 days
                  </button>
                  <button
                    @click="last30Days"
                    class="btn btn-light-primary btn-block mb-2"
                  >
                    Last 30 days
                  </button>
                  <button
                    @click="thisMonth"
                    class="btn btn-light-primary btn-block mb-2"
                  >
                    This month
                  </button>
                  <button
                    @click="prevMonth"
                    class="btn btn-light-primary btn-block mb-2"
                  >
                    Last month
                  </button>
                </div>
                <div class="col-md-9">
                  <DatePicker
                    ref="picker"
                    style="width: 100%"
                    locale="hr"
                    v-model="range"
                    is-range
                    :step="1"
                    :columns="$screens({ sm: 1, md: 2, lg: 3, xl: 3 })"
                    :attributes="attributes"
                    :masks="masks"
                    is24hr
                    is-expanded
                    @input="onRangeSelect"
                    :max-date="new Date()"
                  />
                </div>
              </div>
            </div>
          </div>
          <!--end::Body-->
        </div>
      </div>
    </div>
    <div v-if="isMaintenancesLoaded" class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0 pt-5 pb-0">
            <div class="card-title"></div>
            <div class="card-toolbar">
              <button
                @click="showAllComments"
                class="btn btn-light-primary font-weight-bold px-5 mr-3"
              >
                {{ commentButtonText }}
              </button>
              <button
                @click.prevent="exportAsExcel"
                class="btn btn-light-primary font-weight-bold px-10"
              >
                Export .xlsx
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div v-if="!isDeviceMaintenancesEmpty" class="table-responsive">
                  <table
                    class="table table-head-custom table-head-bg table-vertical-center"
                  >
                    <thead>
                      <tr class="bg-gray-100 text-left">
                        <th class="pl-6">{{ $t('GENERAL.DEVICE') }}</th>
                        <th>{{ $t('GENERAL.LOCATION') }}</th>
                        <th>
                          <span
                            @click="orderByIsMoneyRaised"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{
                                'text-primary': isMoneyRaisedOrder.isActive,
                              }"
                            >
                              Money Raised</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': isMoneyRaisedOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="isMoneyRaisedOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th>
                          <span
                            @click="orderByIsLabelChecked"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{
                                'text-primary': isLabelCheckedOrder.isActive,
                              }"
                            >
                              Label Checked</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary':
                                  isLabelCheckedOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="isLabelCheckedOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th>
                          <span
                            @click="orderByIsDeviceTested"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{
                                'text-primary': isDeviceTestedOrder.isActive,
                              }"
                            >
                              Device Tested</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary':
                                  isDeviceTestedOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="isDeviceTestedOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th>
                          <span
                            @click="orderByIsDeviceReset"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{
                                'text-primary': isDeviceResetOrder.isActive,
                              }"
                            >
                              Device Reset</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': isDeviceResetOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="isDeviceResetOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th>
                          <span
                            @click="orderByIsCoinAcceptorCleaned"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{
                                'text-primary':
                                  isCoinAcceptorCleanedOrder.isActive,
                              }"
                            >
                              Coin Acceptor Cleaned</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary':
                                  isCoinAcceptorCleanedOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="
                                  isCoinAcceptorCleanedOrder.query === 'asc'
                                "
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th>Comment</th>
                        <th>
                          <span
                            @click="orderByCreatedAt"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{
                                'text-primary': createdAtOrder.isActive,
                              }"
                            >
                              Date</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': createdAtOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="createdAtOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th class="text-right pr-6">User</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(maintenance, key) in deviceMaintenances"
                      :key="key"
                    >
                      <tr>
                        <td :class="{ 'border-top-0': key === 0 }" class="pl-6">
                          <span
                            class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                            >{{ maintenance.device.name }}</span
                          >
                          <div>
                            <span
                              class="label label-sm label-light-primary label-inline mr-2"
                              >{{ maintenance.device.tags[0].name }}</span
                            >
                          </div>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span>{{ maintenance.device.location.title }}</span>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            v-if="maintenance.data.isMoneyRaised"
                            class="label label-lg label-light-success label-inline"
                            >{{ $t('GENERAL.YES') }}</span
                          >
                          <span
                            v-else
                            class="label label-lg label-light-danger label-inline"
                            >{{ $t('GENERAL.NO') }}</span
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            v-if="maintenance.data.isLabelChecked"
                            class="label label-lg label-light-success label-inline"
                            >{{ $t('GENERAL.YES') }}</span
                          >
                          <span
                            v-else
                            class="label label-lg label-light-danger label-inline"
                            >{{ $t('GENERAL.NO') }}</span
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            v-if="maintenance.data.isDeviceTested"
                            class="label label-lg label-light-success label-inline"
                            >{{ $t('GENERAL.YES') }}</span
                          >
                          <span
                            v-else
                            class="label label-lg label-light-danger label-inline"
                            >{{ $t('GENERAL.NO') }}</span
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            v-if="maintenance.data.isDeviceReset"
                            class="label label-lg label-light-success label-inline"
                            >{{ $t('GENERAL.YES') }}</span
                          >
                          <span
                            v-else
                            class="label label-lg label-light-danger label-inline"
                            >{{ $t('GENERAL.NO') }}</span
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            v-if="maintenance.data.isCoinAcceptorCleaned"
                            class="label label-lg label-light-success label-inline"
                            >{{ $t('GENERAL.YES') }}</span
                          >
                          <span
                            v-else
                            class="label label-lg label-light-danger label-inline"
                            >{{ $t('GENERAL.NO') }}</span
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <button
                            @click.prevent="
                              toggleDeviceServiceModeHistoryCommentModal(
                                maintenance.data.comment
                              )
                            "
                            class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/Clothes/Sun-glasses.svg"
                              />
                            </span>
                          </button>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span>{{
                            maintenance.createdAt | formatDateTime
                          }}</span>
                        </td>
                        <td
                          :class="{ 'border-top-0': key === 0 }"
                          class="text-right pr-6"
                        >
                          <span>{{ maintenance.author }}</span>
                        </td>
                      </tr>
                      <tr v-if="isAllCommentsVisible">
                        <td class="px-6 border-top-0" colspan="9">
                          <span class="font-weight-bold">Full comment:</span>
                          <span> {{ maintenance.data.comment }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-else>
                  <div class="alert alert-primary mb-0" role="alert">
                    This Device have no Maintenance History
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-modal
          id="device_service_mode_history_comment"
          size="md"
          centered
          hide-header
          hide-footer
        >
          <div v-if="isCommentVisible">
            <b-button
              class="btn btn-light-primary mb-3"
              block
              @click="$bvModal.hide('device_service_mode_history_comment')"
              >Close</b-button
            >
            <div>
              {{ currentEntityComment }}
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

import { mapGetters } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import addDays from 'date-fns/addDays'
import add from 'date-fns/add'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import { format } from 'date-fns'
import axios from 'axios'

export default {
  name: 'ByRangeMaintenance',
  components: {
    DatePicker,
  },
  computed: {
    ...mapGetters(['currentUserRole', 'currentUserCompany']),
  },
  data() {
    return {
      devices: [],
      selectedDevice: null,
      deviceMaintenances: [],
      isMaintenancesLoaded: false,
      isDeviceMaintenancesEmpty: false,
      currentEntityComment: null,
      isCommentVisible: false,
      isAllCommentsVisible: false,
      range: {
        start: null,
        end: null,
      },
      masks: {
        input: 'dd.MM.yyyy - HH:mm:ss',
      },
      attributes: [
        {
          key: 'today',
          highlight: true,
          dates: null,
        },
      ],
      commentButtonText: 'Show All Comments',
      isMoneyRaisedOrder: {
        query: 'asc',
        isActive: false,
      },
      isLabelCheckedOrder: {
        query: 'asc',
        isActive: false,
      },
      isDeviceTestedOrder: {
        query: 'asc',
        isActive: false,
      },
      isDeviceResetOrder: {
        query: 'asc',
        isActive: false,
      },
      isCoinAcceptorCleanedOrder: {
        query: 'asc',
        isActive: false,
      },
      createdAtOrder: {
        query: 'asc',
        isActive: false,
      },
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Device Maintenance by Range', route: '' },
    ])

    if (
      this.currentUserRole === 'ROLE_ADMIN' ||
      this.currentUserRole === 'ROLE_WORKER' ||
      this.currentUserRole === 'ROLE_JUNIOR'
    ) {
      ApiService.get(
        'devices',
        `?company=${this.currentUserCompany.replace(
          '/api/v1/companies/',
          ''
        )}&pagination=false`
      ).then(({ data }) => {
        this.devices = [...data['hydra:member']]
      })
    }
  },
  methods: {
    async getDevices(resource) {
      try {
        return await ApiService.get(resource)
      } catch (e) {
        throw new Error(e)
      }
    },
    toggleDeviceServiceModeHistoryCommentModal(comment) {
      this.isCommentVisible = false
      this.currentEntityComment = comment
      this.isCommentVisible = true

      this.$root.$emit('bv::show::modal', 'device_service_mode_history_comment')
    },
    yesterday() {
      this.$refs.picker.updateValue({
        start: addDays(new Date(this.today), -1),
        end: addDays(new Date(this.today), 0),
      })
    },
    last7Days() {
      this.$refs.picker.updateValue({
        start: add(new Date(), { days: -8 }),
        end: add(new Date(), { days: -1 }),
      })
    },
    last30Days() {
      this.$refs.picker.updateValue({
        start: add(new Date(), { days: -31 }),
        end: add(new Date(), { days: -1 }),
      })
    },
    thisMonth() {
      const today = new Date()

      this.$refs.picker.updateValue({
        start: startOfMonth(today),
        end: today,
      })
    },
    prevMonth() {
      this.$refs.picker.updateValue({
        start: add(startOfMonth(new Date()), { months: -1 }),
        end: add(endOfMonth(new Date()), { months: -1 }),
      })
    },
    onRangeSelect() {
      const start = format(new Date(this.range.start), 'yyyy-MM-dd')
      const end = format(addDays(new Date(this.range.end), +1), 'yyyy-MM-dd')

      ApiService.get(
        'device_maintenances',
        `?device.company=${this.currentUserCompany}&createdAt[after]=${start}&createdAt[before]=${end}`
      ).then(({ data }) => {
        this.deviceMaintenances = [...data['hydra:member']]
        this.isMaintenancesLoaded = true
        this.isDeviceMaintenancesEmpty = this.deviceMaintenances.length === 0
      })
    },
    showAllComments() {
      if (this.isAllCommentsVisible) {
        this.isAllCommentsVisible = false
        this.commentButtonText = 'Show All Comments'
      } else {
        this.isAllCommentsVisible = true
        this.commentButtonText = 'Hide All Comments'
      }
    },
    orderByIsMoneyRaised() {
      this.isMoneyRaisedOrder.isActive = true

      if (this.isMoneyRaisedOrder.query === 'asc') {
        this.isMoneyRaisedOrder.query = 'desc'
        this.deviceMaintenances.sort(
          (a, b) => a.data.isMoneyRaised - b.data.isMoneyRaised
        )
      } else {
        this.isMoneyRaisedOrder.query = 'asc'
        this.deviceMaintenances.sort(
          (a, b) => b.data.isMoneyRaised - a.data.isMoneyRaised
        )
      }
    },
    orderByIsLabelChecked() {
      this.isLabelCheckedOrder.isActive = true

      if (this.isLabelCheckedOrder.query === 'asc') {
        this.isLabelCheckedOrder.query = 'desc'
        this.deviceMaintenances.sort(
          (a, b) => a.data.isLabelChecked - b.data.isLabelChecked
        )
      } else {
        this.isLabelCheckedOrder.query = 'asc'
        this.deviceMaintenances.sort(
          (a, b) => b.data.isLabelChecked - a.data.isLabelChecked
        )
      }
    },
    orderByIsDeviceTested() {
      this.isDeviceTestedOrder.isActive = true

      if (this.isDeviceTestedOrder.query === 'asc') {
        this.isDeviceTestedOrder.query = 'desc'
        this.deviceMaintenances.sort(
          (a, b) => a.data.isDeviceTested - b.data.isDeviceTested
        )
      } else {
        this.isDeviceTestedOrder.query = 'asc'
        this.deviceMaintenances.sort(
          (a, b) => b.data.isDeviceTested - a.data.isDeviceTested
        )
      }
    },
    orderByIsDeviceReset() {
      this.isDeviceResetOrder.isActive = true

      if (this.isDeviceResetOrder.query === 'asc') {
        this.isDeviceResetOrder.query = 'desc'
        this.deviceMaintenances.sort(
          (a, b) => a.data.isDeviceReset - b.data.isDeviceReset
        )
      } else {
        this.isDeviceResetOrder.query = 'asc'
        this.deviceMaintenances.sort(
          (a, b) => b.data.isDeviceReset - a.data.isDeviceReset
        )
      }
    },
    orderByIsCoinAcceptorCleaned() {
      this.isCoinAcceptorCleanedOrder.isActive = true

      if (this.isCoinAcceptorCleanedOrder.query === 'asc') {
        this.isCoinAcceptorCleanedOrder.query = 'desc'
        this.deviceMaintenances.sort(
          (a, b) => a.data.isCoinAcceptorCleaned - b.data.isCoinAcceptorCleaned
        )
      } else {
        this.isCoinAcceptorCleanedOrder.query = 'asc'
        this.deviceMaintenances.sort(
          (a, b) => b.data.isCoinAcceptorCleaned - a.data.isCoinAcceptorCleaned
        )
      }
    },
    orderByCreatedAt() {
      this.createdAtOrder.isActive = true

      if (this.createdAtOrder.query === 'asc') {
        this.createdAtOrder.query = 'desc'
        this.deviceMaintenances.sort((a, b) => a.createdAt - b.createdAt)
      } else {
        this.createdAtOrder.query = 'asc'
        this.deviceMaintenances.sort((a, b) => b.createdAt - a.createdAt)
      }
    },
    exportAsExcel() {
      const from = format(this.range.start, 'yyyy-MM-dd')
      const end = format(this.range.end, 'yyyy-MM-dd')

      axios
        .get(
          `${process.env.VUE_APP_ROUTE_API_URL}/api/v1/device_maintenances/export/in-date-range/${from}/${end}/${this.currentUserCompany.replace(
            '/api/v1/companies/',
            ''
          )}`
        )
        .then(({ data }) => {
          if (data.status === 'ok') {
            window.location.replace(data.fileUrl)
            this.$notify({
              group: 'notification',
              type: 'success',
              title: 'Export success',
            })
          }
        })
    },
  },
}
</script>

<style lang="scss"></style>
